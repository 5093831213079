
*{
	margin: 0px;
	padding: 0px;
}

.App {
  text-align: center;
}


body {
  justify-content:center;
  background: #eeeee4!important;
  padding: 0px;
}

.card {
	margin: 1rem;
}

.quickstart-container {
	display:flex;
	margin-top:5rem;
	justify-content:center;
}

.quickstart-content p {
	padding: 0 5rem 0 5rem;
}


.quickstart-img {
	display:block;
	width:40rem;
	height:auto;
	margin-left:auto;
	margin-right:auto;
	padding:0.5rem;
}

.quickstart-content {
	width:80vw;
	padding: 2rem;
}

.quickstart-content a {
	color:#2cac9b;
	font-weight:bold;
}

.publicHome {
	margin: 0px;
	padding: 0px 2rem;
	background: #2cac9b;
}

.forecast-container-wrapper {
	margin-top: 4.5rem;
	width: 100%;
	height: 70vh;
}

.forecast-container {
	display: flex;
	justify-content: center;
	flex-wrap:wrap;
	align-items: center;
}

.forecast-metric {
	width: 41rem;
	height: auto;
}

.forecast-graph{
	padding:0rem 0.5rem 0.5rem 0.5rem;
}

.forecast-graph-title{
	padding:0.5rem 0.5rem 0rem 0.5rem;
	text-align: center;
}

.training-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
}

.sign-up-container {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;	
}

.sign-up-error-message {
	margin-top: 1rem;
}

.server-stack {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 6rem;
	height: 18rem;
	width: 18rem;
	position: relative;
	overflow: visible;
	margin: 1.5rem;
}

.server-layer {
	position: absolute;
	width: 15rem;
	height: auto;
	transition: top ease 0.5s;
}
.server-layer:nth-child(1) {
	top: 3rem;
	z-index: 4;
}
.server-layer:nth-child(2) {
	z-index: 3;
	top: 6rem;
}
.server-layer:nth-child(3) {
	top: 9rem;
	z-index: 2;
}
.server-layer:nth-child(4) {
	z-index: 1;
	top: 11rem;
	opacity: 70%;
	transition: opacity 0.5s;
}

.server-stack:hover .server-layer:nth-child(1) {
	top:0.5rem;
}
.server-stack:hover .server-layer:nth-child(2) {
	top:5rem;
}
.server-stack:hover .server-layer:nth-child(3) {
	top:8.5rem;
}
.server-stack:hover .server-layer:nth-child(4) {
	opacity:50%;
}


.bd-placeholder-img {
  font-size: 1.125rem;
  padding: 1rem 1rem 0px 1rem;
  max-height: 20rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.dashboard-title {
	margin-top:3rem;
}

.dashboard-main-title {
	margin-top:7rem;
}

.dashboard-metrics {
	display:flex;
	flex-direction:row;
	justify-content: center;
	flex-wrap:wrap;
	align-items: stretch;
}

.dashboard-metric {
	display:flex!important;
	flex: 1;
	flex-direction: column!important;
	margin: 1rem;
	min-width:20rem!important;
	transition: transform .2s;
}

.dashboard-metric:hover {
	z-index: 100;
	transform: scale(1.1);
}

.graph-constraints {
	display:flex;
	flex-direction:row;
	justify-content: center;
	flex-wrap:wrap;
	align-items: stretch;
}

.graph-constraints {
	display:flex!important;
	flex: 1;
	flex-direction: column!important;
	margin: 1rem;
	min-width:20rem!important;
	transition: transform .2s;
}


.dashboard-chart {
	padding: 1rem;
	max-height: 20rem;
}


.overall-container {
	display:flex;
	justify-content: center;
	flex-direction:row;
}

.overall-monthly-emissions {
	display: flex;
	flex-direction:column;
	justify-content: center;
}

.overall-monthly-emissions p {
	margin-bottom: 0px;
}

.overall-monthly-emissions p:last-child {
	margin-bottom: 1rem;
}

.dashboard-overall-chart {
	max-width:80rem;
	min-width:45rem;
	padding: 1rem;
}

.dashboard-overall-chart-options {
	width:20rem;
	min-width:20rem;
	padding: 1rem;
}

.dashboard-metric-items {
	display: flex;
	justify-content: space-between;
	padding: 1rem;
}

.dashboard-metric-title {
	padding: 1rem 1rem 0px 1rem;
	font-weight: bold;
}

.dashboard-container {
	margin: 8rem 2rem 0 2rem;
}

.comparator_containers {
	height: auto;
	width: 5rem;
}

.td_tags{
	vertical-align: middle;
	text-align: center;
}

.pie-compare-split {
	display:flex;
	flex-direction:row;
}

.pie-container {
	max-width:30rem;
	flex: 1;
	padding:1rem;
}

.compare-container {
	display: flex;
	justify-content:center;
	align-items: center;
	flex: 1;
}

.card-container {
	display:flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0px 2rem 0px 2rem;
}

.public-home-card {
	max-width: 40%;
	box-sizing: border-box;
	margin: 2rem 1rem 2rem 1rem;
	background: white;
	padding: 1rem;
	border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
	overflow: hidden;
	animation: morph 12s linear infinite;
}

.public-home-card:nth-child(1) { animation-delay: 1s }
.public-home-card:nth-child(2) { animation-delay: 3s }
.public-home-card:nth-child(3) { animation-delay: 5s }
.public-home-card:nth-child(4) { animation-delay: 7s }

.thought-text {
	font-size: 1.2rem;
	margin: 1.5rem;
}

.off-black-text {
	color: white;
}

.bg-hero {
  animation: gradient 12s ease infinite;
  justify-content:center;
}

.graph-card {
	border-radius: 15px!important;
	max-width: 30%;
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    background-color: #299885 !important;
	border-color: #299885!important;
}

.btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary:visited {
	border-color: #299885!important;
	color: #299885!important;
}
.btn-outline-primary:hover{
	background-color: #299885 !important;
	color: white!important;
}
.thought {
  display:flex;
  background-color:white;
  min-height: 3rem;
  padding:1.5rem;
  margin-top:3rem;
  font-size: 1rem;
  align-items:center;
  text-align:center;
  font-size: 28px;
}

.table-borderless td,
.table-borderless th {
    border: 0;
}

.speech {
  display:flex;
  background-color:#40b97b;
  padding:20px;
  border-radius:20px;
  min-width:250px;
  max-width:500px;
  margin:50px;
  position:relative;
  align-items:center;
  justify-content:center;
  text-align:center;
  justify-content:center;
  font-size: 28px;  
}

.speech:before,
.speech:after {
  content:"";
  background-color:#40c989;
  border-radius:50%;
  display:block;
  position:absolute;
  z-index:-1;
}
.speech:before {
  width:44px;
  height:44px;
  top:-12px;
  left:28px;
  box-shadow:-50px 30px 0 -12px #40ceaa
}
.speech:after {
  bottom:-10px;
  right:26px;
  width:30px;
  height:30px;
  box-shadow:40px -34px 0 0 #40c989,
             -28px -6px 0 -2px #40ceaa,
             -24px 17px 0 -6px #40c989,
             -5px 25px 0 -10px #40b2ce;
}

#startError, #endError {
  color: red;
}

.bg-cardone{
  background-color:hsla(150, 86%, 80%, 0.363);
  border-radius: 35px;

}

.bg-cardtwo{
  background-color:hsla(160, 86%, 80%, 0.363);
  border-radius: 35px; 
}

.bg-cardthree{
  background-color:hsla(170, 86%, 80%, 0.363);
  border-radius: 35px;
}

.bg-footer{
  background-color:rgba(226, 245, 235, 0.699);
}

.f1 {
  font-family:  Arial, sans-serif;
  font-size: 60px;
}
 
svg {
  display: inline-block;
  vertical-align: middle;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.map {
  width: 50vw;
  height: 50vh;
}


.carousel-control-prev-icon, .carousel-control-next-icon {
  background-color: black;
}

.carousel-caption {
  color: black;
}

.graphYAxis {
  font-size: small;
}

#comparatorTable {
	display: absolute;
	font-size: small;
	width: auto;
}

#divToPrint, #divToPrint2, #divToPrint3 , #divToPrint4  {
	font-size: x-small;
	margin-top: 1rem;
	margin-left: 0.2rem;
	width: 20rem;
}

.summaryCard {
	width: 20.5rem;
}

.reportHeader th {
	border: 1px solid black;
}

.reportBody td {
	border: 1px solid black;
}

.reportHeader {
	width: 90%
}

.reportHeader th,
.reportBody td {
  padding: 5px;
  /* Apply cell padding */
}

@keyframes morph{
  0%, 100% {
  border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%;
  }
  34% {
    border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
  }
  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
  }
}

@media (max-width: 1180px) {
	.public-home-card {
		width: 80vw;
		padding: 0px;
		max-width: 100%;
		box-sizing: border-box;
	}
	.sign-up-container{
		margin: 0rem 20% 0rem 20%;
	}
	.thought-text {
		padding: 2rem;
	}
	.publicHome{
		padding: 0px;
	}
	.card-container {
		padding: 1rem;
	}
	.bd-placeholder-img {
		max-height: 20rem;
	}
	.dashboard-container{
		margin: 7rem 0rem 0rem 0rem;
	}
	.dashboard-title {
		margin-top: 3rem;
	}
	.overall-container {
		display:flex;
		flex-direction:column;
	}
	.dashboard-overall-chart{
		width:100%;
		min-width:100%;
	}
	.pie-compare-split {
		flex-direction:column;
	}
	.dashboard-metric {
		transition: none;
	}
	.dashboard-metric:hover {
		transition: none;
		transform: none;
	}
	.quickstart-content {
		width:93vw;
	}
	.quickstart-img{
		width:85%;
	}
	.quickstart-content p {
		padding: 0 0 0 0;
	}

}

@media (max-width: 650px) {
	.dashboard-container {
		margin: 0rem 0rem 0 0rem;
	}
	.quickstart-img{
		width:100%;
	}
	#dashboard-graphOptions {
		align-items: center;
	}
	#divToPrint, #divToPrint2, #divToPrint3 , #divToPrint4  {
		font-size: xx-small;
		margin-top: 0.2rem;
		margin-left: 0.2rem;
		width: 15rem;
	}
}
