.div-center {
  width: 400px;
  height: 400px;
  background-color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 1em 2em;
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  border-left: 2px solid #ccc;
  border-right: 2px solid #ccc;
  display: table;
}

div.content {
  display: table-cell;
  vertical-align: middle;
}

.backHome {
  vertical-align: right;
  position: absolute;
  right: 2rem;
}

.forwardToDash {
  position: absolute;
}
