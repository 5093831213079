.Loading {
    position: absolute;
    height: 100px;
    width: 100px;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    background-size: 100%;
    text-align: center;
}

.loadingText:after {
	content: '...';
  	animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
	0%, 20% {
	  color: rgba(0,0,0,0);
	  text-shadow:
		2em 0 0 rgba(0,0,0,0),
		5em 0 0 rgba(0,0,0,0);}
	40% {
	  color: white;
	  text-shadow:
		2em 0 0 rgba(0,0,0,0),
		5em 0 0 rgba(0,0,0,0);}
	60% {
	  text-shadow:
		2em 0 0 white,
		5em 0 0 rgba(0,0,0,0);}
	80%, 100% {
	  text-shadow:
		2em 0 0 white,
		5em 0 0 white;}}