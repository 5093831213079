@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crete+Round&display=swap');

.navbar-brand{
	font-size:1.2rem;
	font-family: 'Crete Round', serif;
}

  .navbar {
    margin-bottom: 20px;
    align-items: center;
    background: linear-gradient(20deg, #228e96,#4ec443);
    display: flex;
  }

  .cursor-pointer {
	  cursor: pointer;
  }

  .nav-link {
	  cursor: pointer;
  }

  .bg-navbar {
    background: linear-gradient(20deg, #228e96,#4ec443) ;
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
  }
  .dropdown-menu{ 
    background: linear-gradient(80deg, #228e96,#2cac9b) 
  }

  .dropdown-item:hover{
    background-color: #61cca3;
  }

  #dropdownMenuButton1 {
    padding: 0.45rem;
  }

  .btn-outline {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  .text-colour{
    color: #ffffffa6 !important;
  }

  .text-colour:hover{
    color: #FFFFFF !important;
  }

  .hamburger {
    margin-right: 20px;
    display: none;
  }

  .bar, .barClose {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #ffffff;
  }

  @keyframes growDown {
    0% {
      transform: scaleY(0)
    }
    80% {
      transform: scaleY(1.1)
    }
    100% {
      transform: scaleY(1)
    }
  }

  .barClose:nth-child(2) {
    opacity: 0;
  }
  .barClose:nth-child(1) {
  -webkit-transform: translateY(8px) rotate(45deg);
  transform: translateY(8px) rotate(45deg);
  }
  .barClose:nth-child(3) {
  -webkit-transform: translateY(-8px) rotate(-45deg);
  transform: translateY(-8px) rotate(-45deg);
  }
  
  @media only screen and (max-width: 768px) {
    .navbar {
        position: fixed;
        flex-direction: column;
        background-color: #228e96;
        width: 100%;
    }

    .links {
        display: none;
    }

    .hamburger {
        display: block;
        cursor: pointer;
    }

    #hidden{
      animation: growDown 600ms ease-in-out forwards;
      transform-origin: top center;
      transition: 0.5s;
      position: absolute;
      right: 0px;
      top: 55px;
      height: auto;
      width: 100%;
      background: linear-gradient(60deg, #228e96,#4ec443) ;
      background-size: 400% 400%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    #show {
      animation: growDown 600ms ease-in-out backwards;
      transform-origin: top center;
      transition: 0.5s;
      position: absolute;
      right: -769px;
      top: 55px;
      height: auto;
      width: 100%;
      background: linear-gradient(60deg, #228e96,#4ec443) ;
      background-size: 400% 400%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .navbar-collapse {
      display: flex;
      flex-direction: column;
      align-items: center;
      color:#FFFFFF;
      text-align: center;
    }

    .navbar-nav, #dropdownMenuButton1 {
      display: inline;
      font-size: 1.3rem;
      flex-direction: column;
      align-items: center;
    }

    

}

#appTitle {
  text-decoration: underline 0.1em rgba(255, 255, 255, 0);
  transition: text-decoration-color 300ms;
}

#appTitle:hover {
  text-decoration-color: rgba(255, 255, 255, 1);
}
